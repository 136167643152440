@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.navbar {
  height: 100px;
}

.navbar-container {
  height: 100px;
  margin: auto;
  max-width: 900px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  @media only screen and (max-width: 992px) {
    max-width: 700px;
  }
  @media only screen and (max-width: 750px) {
    max-width: 600px;
  }
  @media only screen and (max-width: 650px) {
    max-width: 500px;
  }
  @media only screen and (max-width: 550px) {
    max-width: 400px;
  }
  @media only screen and (max-width: 400px) {
    max-width: 375px;
  }
}

.logo-container {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.logo {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-decoration: none;
  padding: 12px;
  margin-left: -12px;

  color: #82faa2;
  width: 50px;
  height: 50px;

  @media only screen and (max-width: 400px) {
    width: 40px;
    height: 40px;
  }
}

.logo-title {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #c9d8e0;
  margin-left: 5px;
  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }
}

.links-container {
  position: relative;
  display: flex;
  align-items: center;
  // right: -10px;
}

.link {
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #c9d8e0;
  margin-left: 30px;
  transition: 0.2s;
  /* text-shadow: #c9d8e0 1px 0 10px; */
  @media only screen and (max-width: 400px) {
    font-size: 14px;
    margin-left: 10px;
  }
}

.link:hover {
  color: #fff;
}

.link-btn {
  border: 1px solid #354953;
  padding: 10px 21px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  transition: 0.3s;
  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }
}

.link-btn:hover {
  background-color: #c9d8e0;
  color: #121a21;
}
