body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: hsla(210, 23%, 17%, 1);

	background: linear-gradient(
		270deg,
		hsla(210, 23%, 17%, 1) 0%,
		hsla(208, 29%, 10%, 1) 58%
	);

	background: -moz-linear-gradient(
		270deg,
		hsla(210, 23%, 17%, 1) 0%,
		hsla(208, 29%, 10%, 1) 58%
	);

	background: -webkit-linear-gradient(
		270deg,
		hsla(210, 23%, 17%, 1) 0%,
		hsla(208, 29%, 10%, 1) 58%
	);

	filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#212B35", endColorstr="#121A21", GradientType=1 );
}

a {
	text-decoration: none;
}
