.projects-container {
  max-width: 900px;
  margin: auto;
  margin-top: 120px;
  padding: 15px;
  @media only screen and (max-width: 992px) {
    max-width: 700px;
  }
  @media only screen and (max-width: 750px) {
    max-width: 600px;
  }
  @media only screen and (max-width: 650px) {
    max-width: 500px;
  }
  @media only screen and (max-width: 550px) {
    max-width: 400px;
  }
  @media only screen and (max-width: 400px) {
    max-width: 375px;
    margin-top: 80px;
  }
}

.projects-header {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #c9d8e0;
}

.card-container {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 20px;
}
