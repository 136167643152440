.footer {
  margin-top: 100px;
  @media only screen and (max-width: 400px) {
    margin-top: 60px;
  }
}

.contact-container {
  height: 350px;
  background-color: #1c2d35;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.contact-title {
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: 600;
  color: #82faa2;
  display: flex;
  text-align: center;
  @media only screen and (max-width: 400px) {
    font-size: 26px;
  }
}

.contact-subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #c9d8e0;
  margin-top: 30px;
  line-height: 28px;
  max-width: 500px;
  display: flex;
  text-align: center;
  @media only screen and (max-width: 400px) {
    font-size: 12 px;
  }
}

.contact-btn {
  margin-top: 40px;
  font-family: "Poppins", sans-serif;
  background-color: #82faa2;
  border: 2px solid #82faa2;
  color: #121a21;
  padding: 10px 21px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  transition: 0.3s;
}

.contact-btn:hover {
  background-color: transparent;
  color: #82faa2;
}

.footer-lower {
  height: 160px;
}

.footer-container {
  margin: auto;
  max-width: 900px;
  height: 160px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media only screen and (max-width: 992px) {
    max-width: 700px;
  }
  @media only screen and (max-width: 750px) {
    max-width: 600px;
  }
  @media only screen and (max-width: 650px) {
    max-width: 500px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 550px) {
    max-width: 400px;
  }
  @media only screen and (max-width: 400px) {
    max-width: 375px;
  }
}

.footer-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-content {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #5e7f91;
  display: flex;
  align-items: center;
  margin: 2px 0;
  white-space: pre;
  letter-spacing: 0.5px;
  @media only screen and (max-width: 650px) {
    justify-content: center;
  }
  @media only screen and (max-width: 400px) {
    max-width: 375px;
    font-size: 10px;
  }
}

.footer-content-light {
  color: #c9d8e0;
}

.footer-socials {
  position: relative;
  display: flex;
  align-items: center;
  right: -10px;
}

.social {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c9d8e0;
  margin: 0 3px 0 3px;
  transition: 0.2s;
  border-radius: 5px;
  text-align: center;
  padding-top: auto;
  padding-bottom: auto;
}

.social:hover {
  color: #121a21;
  background-color: #c9d8e0;
  cursor: pointer;
}
