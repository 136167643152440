.experience-container {
  max-width: 900px;
  margin: auto;
  margin-top: 120px;
  padding: 15px;
  @media only screen and (max-width: 992px) {
    max-width: 700px;
  }
  @media only screen and (max-width: 750px) {
    max-width: 600px;
  }
  @media only screen and (max-width: 650px) {
    max-width: 500px;
  }
  @media only screen and (max-width: 550px) {
    max-width: 400px;
  }
  @media only screen and (max-width: 400px) {
    max-width: 375px;
    margin-top: 80px;
  }
}

.experience-header {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #c9d8e0;
}

.experience-list {
  margin-top: 40px;
  max-width: 600px;
}

.experience {
  max-width: 600px;
  margin-top: 20px;
}

.experience-title {
  display: flex;
  align-items: center;
}

.experience-icon {
  display: flex;
  align-items: center;

  width: 25px;
  height: 25px;
}

.experience-title-text {
  margin-left: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #c9d8e0;
  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }
}

.experience-description {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #49616f;
  margin-left: 45px;
}
