.card {
  display: flex;
  background-color: #1c2d35;
  width: 440px;
  height: 250px;
  border-radius: 7px;
  overflow: hidden;
  @media only screen and (max-width: 992px) {
    max-width: 700px;
    width: 700px;
  }
  @media only screen and (max-width: 750px) {
    max-width: 600px;
    width: 600px;
  }
  @media only screen and (max-width: 650px) {
    max-width: 500px;
    width: 500px;
  }
  @media only screen and (max-width: 550px) {
    max-width: 400px;
  }
}

.card-large {
  display: flex;
  background-color: #1c2d35;
  width: 900px;
  height: 250px;
  border-radius: 7px;
  overflow: hidden;
  @media only screen and (max-width: 992px) {
    max-width: 700px;
  }
  @media only screen and (max-width: 750px) {
    max-width: 600px;
  }
  @media only screen and (max-width: 650px) {
    max-width: 500px;
  }
  @media only screen and (max-width: 550px) {
    max-width: 400px;
  }
}

.color-stripe {
  width: 5px;
  height: 100%;
}

.card-title {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #c9d8e0;
  @media only screen and (max-width: 550px) {
    max-width: 300px;
    font-size: 22px;
  }
}

.card-info {
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: #c9d8e0;
  width: 350px;
  @media only screen and (max-width: 992px) {
    max-width: 500px;
  }
  @media only screen and (max-width: 750px) {
    max-width: 400px;
  }
  @media only screen and (max-width: 550px) {
    max-width: 300px;
    font-size: 12px;
  }
  &-large {
    @extend .card-info;
    width: 600px;
    @media only screen and (max-width: 992px) {
      max-width: 500px;
      width: 500px;
    }
    @media only screen and (max-width: 750px) {
      max-width: 400px;
      width: 400px;
    }
    @media only screen and (max-width: 550px) {
      max-width: 300px;
      font-size: 12px;
    }
  }
}

.card-content-container {
  display: flex;
  flex-direction: column;
  margin: 30px 0 0 40px;
}

.card-button {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #c9d8e0;
  margin-top: 30px;
  width: 70px;
  text-align: center;
  border: 2px solid #c9d8e0;
  padding: 10px 21px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  transition: 0.3s;
}

.card-button:hover {
  background-color: #c9d8e0;
  color: #1c2d35;
}
