@import "../App.scss";

.header-container {
  overflow: visible;

  max-width: 900px;
  margin: auto;
  margin-top: 80px;
  padding: 15px;
  @media only screen and (max-width: 992px) {
    max-width: 700px;
  }
  @media only screen and (max-width: 750px) {
    max-width: 600px;
  }
  @media only screen and (max-width: 650px) {
    max-width: 500px;
  }
  @media only screen and (max-width: 550px) {
    max-width: 400px;
  }
  @media only screen and (max-width: 400px) {
    max-width: 375px;
    margin-top: 40px;
  }
}

// .header-container::before {
//   // background-image: url("../assets/art/diamond_shadow.svg"),
//   //   url("../assets/art/diamond_shadow_left.svg");
//   // background-repeat: no-repeat, no-repeat;
//   // background-position: right top, -10px bottom;
//   // background-size: 100% 100%;
//   content: " ";
//   position: fixed;
//   width: 260px;
//   height: 200px;
//   z-index: -1;
//   margin-top: -50px;
//   margin-left: 800px;
//   background-image: url("../assets/art/diamond_shadow.svg");
//   background-repeat: no-repeat;
// }

.header-title {
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: 600;
  color: #82faa2;
  text-shadow: rgba(133, 250, 164, 0.4) 1px 0 30px;
  max-width: 650px;
  @media only screen and (max-width: 400px) {
    font-size: 24px;
  }
}

.header-subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #c9d8e0;
  max-width: 580px;
  margin-top: 30px;
  line-height: 28px;
  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }
}

.bg-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  top: 0;
  left: 0;
}
